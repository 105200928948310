<script setup lang="ts">
import { fetchTrendingProducts, useProductsList } from '~/data/products';
import ProductList from '../plp/ProductList.vue';
import FilterBar from '../filter-bar/index.vue';

interface TrendingProductsProps {
	routeName: string;
	noTitle: boolean;
	// eslint-disable-next-line vue/require-default-prop
	nodeId?: Array<number>;
}

const props = withDefaults(defineProps<TrendingProductsProps>(), {
	noTitle: false,
});
const filters = useFilterParams(props.routeName);

const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
	useProductsList({
		queryKey: ['trending_products', props.nodeId, filters],
		queryFn: ({ pageParam: cursor }) => {
			return fetchTrendingProducts(
				cursor as string,
				props.nodeId,
				filters.value,
			);
		},
		routeName: props.routeName,
	});
</script>
<template>
	<h2
		v-if="!noTitle"
		class="lg:text-2xl text-lg lg:px-0 px-3xl font-semibold text-gray-800"
	>
		Trending Products
	</h2>
	<FilterBar :node-ids="nodeId" :static="true" class="static" />
	<ProductList
		:infinite-loader="true"
		:fetch-next-page="fetchNextPage"
		:is-loading="isLoading"
		:data="data"
		:has-next-page="hasNextPage"
		:is-fetching-next-page="isFetchingNextPage"
	/>
</template>
